.portfolio-row {
	width: 100%;
	background-color:#fff;
	min-height:500px;
	padding:30px 0;
	.portfolio-grid{position:relative;width: 100%; height: 520px;@include enable-gpu;}
	.grid-item {
		overflow:hidden;position:relative;height:200px;width:100%;float:left;border:4px solid #fff;background-position:center center;background-repeat:no-repeat;background-size:cover;background-color:$secondary1;@include enable-gpu;
		.grid-screen{
			position:absolute;width:100%;height:40px;background-color:rgba(84,84,84,0.8);@extend.transAllSlow;@include enable-gpu;
		}
		h3{color:$secondary5;opacity:1;position:absolute;margin:0;@extend.transAll;-ms-transition-delay:0.6s;-webkit-transition-delay:0.6s;transition-delay:0.6s;}
		&:hover{@include enable-gpu;}
	}
	.grid-item-2,.grid-item-4{
		.grid-screen{bottom:0;left:0;}
		h3{bottom:5px;left:10px;}
	}
	.grid-item-1,.grid-item-3{
		.grid-screen{bottom:0;right:0;}
		h3{bottom:5px;left:10px;}
	}
	.grid-heading{
		z-index:20;border:4px solid #fff;background-color:$primary1;height:90px;width:100%;text-align:center;
		.heading-info{
			h3{font-size:2.2em;color:$secondary5;margin-top:12px;margin-bottom:0;}
			a{color:$secondary5;
				&:hover{
					@include enable-gpu;
					.floating{
						animation-name: floating;
						-webkit-animation-name: floating;
					}
				}
			}
		}
	}
}

.grid figure {
	position: relative; overflow: hidden;margin: 20px auto;height:200px;text-align: center;cursor: pointer;background:#000;@include enable-gpu;
	.fig-img {
		width:100%;height:200px;background-position:center center;background-size:cover;background-repeat:no-repeat;opacity: 0.5; 
		-webkit-transition: opacity 0.35s; transition: opacity 0.35s;@include enable-gpu;
	}
	figcaption {
		position: absolute;top: 0;left: 0;width: 100%;height: 100%;padding: 3em;
		-webkit-backface-visibility: hidden;backface-visibility: hidden;
	}
	figcaption::before, figcaption::after {
		pointer-events: none;
	}
	figcaption::before {
		position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: url(/stylesheets/webArt/icons/port-circle.svg) no-repeat center center;background-size: 100% auto;content: '';@include enable-gpu;
		-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;transition: opacity 0.35s, transform 0.35s;opacity: 0.6; -webkit-transform: scale3d(1,1,1);transform: scale3d(1,1,1);-webkit-transform-origin: 50% 50%;transform-origin: 50% 50%;@include enable-gpu
	}
	p{
		color:$secondary5; font-size: 3em; opacity: 1; position: relative;
		-webkit-transition: opacity 0.35s, -webkit-transform 0.35s; transition: opacity 0.35s, transform 0.35s;
	}
	h2 {
		font-size:1.2em;color:$secondary5;margin-top: 1.2em;
		-webkit-transition: transform 0.35s;transition: transform 0.35s; -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0);
	}
	&:hover{@include enable-gpu;}
}
.portfolio-filters{
	text-align:center;
	h4{margin-top:0;}
	label{display:block;color:$primary1;}
}
.our-work button{color:$primary1;margin-bottom:10px;}
.our-work-detail{
	.main-img{
		img{margin:0 auto;}
	}
	.work-title{
		text-align:center;
		h2{margin-top:0;color:$primary1;}
	}
	.work-description{
		margin:40px 0;border-top:2px solid $primary1;border-bottom:2px solid $primary1;padding:26px 0 16px;
		.secondary-imgs{
			margin:20px 0;
			img{max-height:120px;margin:0 auto}
		}
	}
	.btn{
		margin-top:20px;
	}
}

@media (min-width: 768px) {
	.portfolio-row {
		padding:70px 0 130px;
		.grid-item {
			width:50%;
			height:260px;
			.grid-screen {
				border-radius:50%;position:absolute;width:1000px;height:1000px;background-color:rgba(84,84,84,0.8);
			}
			h3{color:$secondary5;opacity:0;position:absolute;margin:0;}
		}
		a:nth-of-type(1) {
			.grid-item {
				.grid-screen {
					bottom: -1000px;
					right: -1000px;
				}
				h3 {
					top: 20px;
					left: 20px;
				}
				&:hover {
					.grid-screen {
						position:absolute;bottom:-400px;right:-400px;
					}
					h3 {
						opacity:1;
					}
				}
			}
		}
		a:nth-of-type(2){
			.grid-item{
				h3{top:20px;right:20px;text-align: right;}
				.grid-screen{bottom:-1000px;left:-1000px;}
				&:hover{
					.grid-screen{
						position:absolute;bottom:-400px;left:-400px;
					}
					h3{opacity:1;}
				}
			}
		}
		a:nth-of-type(3){
			.grid-item{
				h3{bottom:20px;left:20px;}
				.grid-screen{top:-1000px;right:-1000px;}
				&:hover{
					.grid-screen{
						position:absolute;top:-400px;right:-400px;
					}
					h3{opacity:1;}
				}
			}
		}
		a:nth-of-type(4){
			.grid-item{
				h3{bottom:20px;right:20px;text-align: right;}
				.grid-screen{top:-1000px;left:-1000px;}
				&:hover{
					.grid-screen{
						position:absolute;top:-400px;left:-400px;
					}
					h3{opacity:1;}
				}
			}
		}
		.grid-heading{
			position:absolute;border:8px solid #fff;border-radius:50%;height:260px;width:260px;left: 50%;margin-left: -130px;top: 50%;margin-top: -130px;text-align:center;display: table;
			.heading-info{display:table-cell;vertical-align:middle;}
		}
	}
	.portfolio-filters{
		text-align:left;
	}	

	.grid figure {
		height:256px;
		.fig-img {
			height:256px;opacity: 0.65;
		}
		&:hover{
			.fig-img{opacity: 0.5;}
		}
		figcaption::before {
			-webkit-transform: scale3d(5,2.5,1);transform: scale3d(5,2.5,1);
		}
		&:hover {
			figcaption::before {
				opacity: 0.6; -webkit-transform: scale3d(1,1,1);transform: scale3d(1,1,1);
			}
		}
		p {
			opacity: 0;
			-webkit-transition: opacity 0.35s, -webkit-transform 0.35s; transition: opacity 0.35s, transform 0.35s;
		}
		&:hover p  {
			opacity: 1;-webkit-transform: translate3d(0,-20px,0);transform: translate3d(0,-20px,0);
		}
		h2 {
			-webkit-transform: translate3d(0,40px,0);transform: translate3d(0,40px,0);
		}
		&:hover h2 {
			-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);
		}
	}
	.portfolio-filters {
		height: auto !important;
		visibility: visible !important;
		display: flex !important;
	}
	.width-check {
		pointer-events: none;
	}
}
@media (min-width:  992px) {
	.grid figure {
		height:218px;
		.fig-img{
			height:218px;
		}
	}
}
@media (min-width:  1200px) {
	.grid figure {
		height:268px;
		.fig-img{
			height:268px;
		}
		h2 {
			font-size:1.5em;
		}
	}
}