/** ********* interior.sass contents ***********

***************************************** **/
.sys-msg{
	text-align:center;
}
#interiorBody{
	main{
		background-color:#fff;min-height:500px;padding:50px 0;
	}
}

.interior-utility-header{
	background-color:$primary1;
	h1{color:$secondary5;font-size:2em;margin:14px;}
	.header-dot{
		background-color: rgba(255,255,255,0.5);width:14px;height:14px;border-radius: 50%;margin:24px 0 24px 10px;
	}
}

.about-us{
	text-align:center;
	h2{color:$primary1;}
	.headshot{
		margin:20px auto 0;height: 150px;width: 150px; border-radius: 50%;border: 6px solid $primary1;background-position: center center;background-size: cover;background-repeat: no-repeat;
	}
	.our-business{
		margin:80px 0;
		h3{
			cursor:pointer;font-size:1.2em;
			.icon{margin-right: 20px;color:$primary1;}
		}
		hr{border-top:2px solid $primary1;}
	}
	.see-work{text-align:center;
		.btn{margin-bottom:40px;}
	}
}

.contact-page{
	text-align:center;
	.map-container{height:500px;margin-bottom:50px;}
	h3{color:$primary1;}
	li.staff-name{margin-top:12px;font-size:20px;}
	.btn{
		color:$primary1;
		&:hover{color:$secondary5;}
	}
}
.contact-form{
	background-color:$secondary3;padding:70px 0 90px;
	.dot-row{
		text-align:center;margin-bottom:60px;
		.teal-dot{width: 16px;height: 16px;background-color:$primary1;display: inline-block;border-radius: 50%;margin:0 40px;}
		.white-dot{width: 16px;height: 16px;background-color:$secondary5;display: inline-block;border-radius: 50%;}
	}
	.contact-section{
		h2{color:$secondary5;text-align:center;font-size:3em;}
		label{color:$secondary5;display:block;}
		label.checkbox{color:$primary1;}
		.btn{margin-top:60px;}
		.form-help-checkbox{
			padding: 18px 0 10px;border-top:2px solid #13a89e;border-bottom:2px solid #13a98e;margin: 16px 0 30px;
			.form-group{
				margin:0;
			}
		}
	}
}

.vision-blog {
	h4 { 
		color: $primary1;
		font-size: 18px;
	}
	.vision-post {
		height: 400px;
		text-align: center;
		.post-img {
			position: relative;
			width: 200px;
			height: 200px;
			border-radius: 50%;
			margin: 0 auto;
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			.border-screen {
				position: absolute;
				top: 0;
				left: 0;
				width: 200px;
				height: 200px;
				border: 10px solid rgba(255,255,255,0.5);
				border-radius: 50%;
				@extend .transAll;
			}
			.icon {
				margin-top: 70px;
				font-size: 4em;
				opacity: 0;
			}
			&:hover .icon {
				opacity:1;
				-ms-transition-delay: 0.3s;
				-webkit-transition-delay: 0.3s;
				transition-delay: 0.3s;
			}
			&:hover .border-screen {
				border:100px solid rgba(255,255,255,0.5);
			}
		}
	}
}
.blog-detail {
	h2{color:$primary1;}
	.blog-body {
		padding: 2.5rem 1.25rem;
		a {
			color:$primary1;

		}
	}
	.post-img{
		position:relative;width:200px;height:200px;border-radius:50%;margin:0 auto;background-position:center center;background-size:cover;background-repeat:no-repeat;
		.border-screen{position:absolute;top:0;left:0;width:200px;height:200px;border:10px solid rgba(255,255,255,0.5);border-radius:50%;@extend.transAll;}
	}
}

.blog-teaser-copy {
	display: block;
	p {
		
	}
}

.blog-read-link {
	display: inline-block;
}

.inner-quote-row{
	background-color:$secondary1;position:relative;
	.table{
		display:table;height:200px;z-index:40;width:100%;text-align:center;margin:0;
		.table-cell{
			display:table-cell;vertical-align:middle;
			blockquote{
				border:none;font-size:1.3em;padding:0;margin:0;
				p{color:$secondary5;}
				footer{color:$secondary4;}
			}
		}
	}
	.col-sm-3:first-child{text-align:right;}
	.quote-one{top:10px;}
	.quote-two{top:50px;margin-left:-50px;}
	.icon{font-size:3em;color:$primary1;position:relative;}
}

.quote-row {
	width: 100%;
	background-color:#fff;
	position:relative;
	.i-icon{background:transparent url(/stylesheets/webArt/icons/i-offwhite.svg) center center no-repeat;background-size:contain;height:500px;}
	.i-one{z-index:20;}
	.i-two{z-index:0;}
	.i-three{z-index:20;}
	.center-bar{
		width:100%;min-height:200px;background-color:$secondary1;z-index:10;
	}
	.quote-container{
		width:100%;min-height:200px;z-index:30;position:absolute;top:0;
		.quote-item{
			.col-md-6{
				.table{
					display:table;height:200px;z-index:40;width:100%;text-align:center;
					.table-cell{
						display:table-cell;vertical-align:middle;
						blockquote{
							border:none;font-size:1.3em;padding:0;margin:0;
							p{color:$secondary5;}
							footer{color:$secondary4;}
						}
					}
				}
			}
			.col-md-3:first-child{text-align:right;}
			.quote-one{top:10px}
			.quote-two{top:50px;margin-left:-40px;}
			.icon{font-size:3em;color:$primary1;position:relative;}
		}
	}
}

@media (min-width: 768px) {
	.about-us{
		text-align:left;
		.profiles{
			h2{margin-bottom:60px;}
		}
		.headshot{
			margin:0;
		}
		.our-business{

		}
	}
	.contact-page{
		text-align:left;
	}
	.contact-form{
		.form-horizontal .control-label{text-align:left;}
	}
	.inner-quote-row{
		.quote-two{margin:0;}
		.icon{font-size:4em;}
	}
	.quote-row{
		min-height:500px;position:relative;
		.center-bar{
			position:absolute;top:30%;
		}
		.quote-container{
			top:30%;
			.quote-item{
				.quote-one{text-align:right;}
				.quote-two{margin:0;}
				.icon{font-size:4em;}
			}
		}
	}
	.vision-blog{
		h4{height:60px;}
	}
}

canvas {
	background:rgba(19,168,158,0.5);position:fixed;top:0;left:0;z-index:200;
}
.map-container {
	canvas {
		background:rgba(19,168,158,0.0);
	}
}


