@charset 'UTF-8';

@import
	'abstracts/variables',
	'abstracts/functions',
	'abstracts/mixins';

@import
	'base/base',
	'base/fonts',
	'base/typography',
	'base/dividers',
	'base/helpers',
	'base/utility';

@import
	'components/navigation',
	'components/header',
	'components/footer',
	'components/buttons',
	'components/banners',
	'components/backgrounds',
	'components/cards',
	'components/dropdowns',
	'components/forms',
	'components/images',
	'components/page-title',
	'components/page-intro',
	'components/pagination',
	'components/sliders',
	'components/video',
	'components/blocks';

@import
	'templates/home',
	'templates/interior',
	'templates/services',
	'templates/portfolio';
