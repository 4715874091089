@mixin enable-gpu {
  transform:(translateZ(0));
}
@mixin hide-text {
  font: 0/0 a; 
  text-shadow: none;
  color: transparent;
}

@mixin nopadding {
  padding:0 !important; 
  margin:0 !important;
}