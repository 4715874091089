.block-banner-outer-container {
	margin: 0;
	padding: 0;
	overflow: hidden;
	aspect-ratio: 16 / 9;
}

// Swiper JS Classes
.banner-slide {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position: relative;
	z-index: 1;
	width: 100%;
	height: auto;
	aspect-ratio: 16 / 9;
}
.banner-image {
	width: 100%;
	height: auto;
	aspect-ratio: 16 / 9;
	// visibility: hidden;
}





// Bootstrap 4.6 Classes
.carousel {
	width: 100%;
	height: auto;
	position: relative;
	z-index: 1;
}

.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	height: calc(100vw * 0.5625)
}

.carousel-image {
	width: 100%;
	height: auto;
	visibility: hidden;
}

.banner-title-overlay {
	position: absolute;
	left: 0;
	bottom: -0.25em;
	width: 100%;
	max-width: 1800px;
	padding: 0;
	margin: 0;
	a.banner-title-link {
		background-color: $white;
		color: $black;
		font-size: 0.6em;
		line-height: 1;
		margin: 0 0 0 10%;
		padding: 0.75em 2.0em;
		-webkit-transition: color 1.0s ease-out;
		-moz-transition: color 1.0s ease-out;
		-ms-transition: color 1.0s ease-out;
		-o-transition: color 1.0s ease-out;
		transition: color 1.0s ease-out;
		&:hover {
			color: $black;
			text-decoration: none;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $black;
			text-decoration: none;
		}
		.fa, .fac {
			color: $black;
			font-size: 1.0em;
			// transform: translateY(-0.00625em);
		}
	}
}

.carousel-caption {
	position: absolute;
	top: 25%;
	left: 25%;
	transform: translateY(-25%);
	transform: translateX(-25%);
	width: 33%;
	height: auto;
	max-width: 900px;
	margin: 0;
	padding: 0;
	@media (max-width: 576px) {
		top: 12% !important; 
		left: 15% !important; 
		transform: translate(0,0) !important;
	}
	h1 {
		font-size: 2.4em;
		margin: 0;
		padding: 0;
	}
	.caption-headline-block {
		position: relative;
		padding-bottom: 0;
	}
	hr.caption-divider {
		overflow: visible; /* For IE */
		padding: 0;
		margin: 0 0 0.625em 0;
		border: none;
		border-top: 1px solid $black;
		color: $white;
		text-align: right;
	}
	.caption-body {
		padding-top: 0;
		p {
			line-height: 1;
		}
	}
}

.carousel-control-prev, .carousel-control-next {
	width: auto;
	padding: 1.25em;
	background-image: none;
  border-radius: 50%;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
}

.carousel-control-prev-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f053";
	color: $white;
	font-size: 2.625em;
}

.carousel-control-next-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f054";
	color: $white;
	font-size: 2.625em;
}

#banner-down-arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	text-align: center;
	pointer-events: none;
}

#banner-down-arrow a {
	position: absolute;
	bottom: 2.0em;
	left: 0;
	width: 100vw;
	-webkit-animation: anim-banner-down-arrow 2s infinite;
	animation: anim-banner-down-arrow 2s infinite;
	.fa, .fac {
		font-size: 2.0em;
		color: $black;
		&:hover {
			color: $black;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $black;
		}
	}
}

@-webkit-keyframes anim-banner-down-arrow {
	0% {
		-webkit-transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(2.0em);
		opacity: 0;
	}
}

@keyframes anim-banner-down-arrow {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(2.0em);
		opacity: 0;
	}
}