.our-services {
	text-align: center;
	.service-thumb{
		margin: 0 auto;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		margin-top: 20px;
		margin-bottom: 20px;
		border: 6px solid $primary1;
	}
	.row {
		margin-bottom: 60px;
	}
	h3{
		color:$primary1;
	}
	.service-description p {
		margin-top: 30px;
		line-height: 2;
		font-size: larger;
	}
}

.bread-and-butter {
	width: 100%;
	background-color:#fff;
	text-align:center;
	padding:20px 0 40px;
	h2 {
		margin-bottom:0;
		color:$secondary1;
		font-size:2.4em;
	}
}

.ch-info-back.table{
	display:table;width:100%;text-align:center;
	.table-cell{
		display:table-cell;vertical-align:middle;
	}
}


/* bread and butter grid - codrops */

.ch-grid:after,
.ch-item:before {
	content: ''; display: table;
}
.ch-grid:after {
	clear: both;
}
.ch-grid {
	width: 220px;height: 220px;margin: 20px auto;
}
.ch-item {
	width: 100%;height: 100%;position: relative;
}
.ch-info-wrap{
	position: absolute;width: 180px;height: 180px;border-radius: 50%;
	top: 20px;left: 20px;background-color:#fff;box-shadow:0 0 0 20px rgba(255,255,255,0.2),inset 0 0 3px rgba(115,114, 23, 0.8);
	-webkit-perspective: 800px;-moz-perspective: 800px;-o-perspective: 800px;-ms-perspective: 800px;perspective: 800px;
	-webkit-transition: all 0.4s ease-in-out;-moz-transition: all 0.4s ease-in-out;-o-transition: all 0.4s ease-in-out;-ms-transition: all 0.4s ease-in-out;transition: all 0.4s ease-in-out;
}
.ch-info{
	position: absolute;width: 180px;height: 180px;border-radius: 50%;
	-webkit-transition: all 0.4s ease-in-out;-moz-transition: all 0.4s ease-in-out;-o-transition: all 0.4s ease-in-out;-ms-transition: all 0.4s ease-in-out;transition: all 0.4s ease-in-out;
	-webkit-transform-style: preserve-3d;-moz-transform-style: preserve-3d;-o-transform-style: preserve-3d;-ms-transform-style: preserve-3d;transform-style: preserve-3d;
}
.ch-info > div {
	display: block;position: absolute;width: 100%;height: 100%;border-radius: 50%;background-position: center center;
	-webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;-o-backface-visibility: hidden;-ms-backface-visibility: hidden;backface-visibility: hidden;
}
.ch-info .ch-info-back {
	background:$secondary1;
	transform: perspective(800px) rotateY(180deg);
//	-webkit-transform: rotate3d(0,1,0,180deg);-moz-transform: rotate3d(0,1,0,180deg);-o-transform: rotate3d(0,1,0,180deg);-ms-transform: rotate3d(0,1,0,180deg);transform: rotate3d(0,1,0,180deg);
}
.ch-info h3 {
	color:$secondary5;
}
.ch-item:hover .ch-info-wrap {
	box-shadow:0 0 0 0 rgba(255,255,255,0.8),inset 0 0 3px rgba(115,114, 23, 0.8);
}
.ch-item:hover .ch-info {
	transform: perspective(800px) rotateY(-180deg);
//	-webkit-transform: rotate3d(0,1,0,-180deg);-moz-transform: rotate3d(0,1,0,-180deg);-o-transform: rotate3d(0,1,0,-180deg);-ms-transform: rotate3d(0,1,0,-180deg);transform: rotate3d(0,1,0,-180deg);
}
.ch-img-1 { 
	background-image: url(/stylesheets/webArt/icons/analytics.svg);
}
.ch-img-2 { 
	background-image: url(/stylesheets/webArt/icons/money.svg);
}
.ch-img-3 { 
	background-image: url(/stylesheets/webArt/icons/pen.svg);
}
.ch-img-4 { 
	background-image: url(/stylesheets/webArt/icons/eventplanning.svg);
}
.ch-img-5 { 
	background-image: url(/stylesheets/webArt/icons/web-design-phone.svg);
}
.ch-img-6 { 
	background-image: url(/stylesheets/webArt/icons/customer-service-training.svg);
}

@media (min-width: 768px) {
	.our-services{
		text-align:left;
	}
}
