body{
	position: relative;
	margin: 0 !important;
	// overflow-x: hidden;
	background-color: $secondary1;
	font-size: 1.0rem;
	color:$secondary1;
	&.nav-open{
		overflow: hidden;
	}
	&.lock{overflow:hidden;}
}

a,p,li,button{
	color:$secondary1;font-family:$fontMain;
	span{font-weight:200;}
}
h1,h2{
	color:$secondary1;font-family:$fontHeading;
	span{font-weight:200;}
}
h3,h4,h5,h6{color:$secondary1;font-family:$fontMain;}
a[href^="tel"]{pointer-events:auto;}
@media (min-width: 768px) {
	a[href^="tel"]{pointer-events:none;}
}
h3 { font-size: 24px;}
a{text-decoration:none;
	&:hover{color:$primary1;text-decoration:none;}
	&.active{}
}
ul{padding: 0;
	li{list-style-type:none;}
}

button, .btn {
	border-radius: 0;
	white-space: normal;
}
.btn-blank {  
	background-color: transparent;
	border: none;
}
.btn-thin {
	border:1px solid $primary1 !important;
}
.btn-thirdeye {
	border: 2px solid $primary1;
	font-family: $fontHeading;
	background-color: transparent;
	color: $secondary5;
	white-space: nowrap;
	@extend .transAll;
	&:hover {
		background-color: $primary1;
		color: $secondary5;
		@extend .transAll;
	}
}
.btn-thirdeye2 {
	border:2px solid $primary1;
	font-family: $fontHeading;
	background-color: transparent;
	color: $primary1;
	@extend .transAll;
	&:hover {
		background-color: $primary1;
		color: $secondary5;
		@extend .transAll;
	}
}
.btn-thirdeye-gray {
	border:2px solid $secondary4;
	font-family: $fontHeading;
	background-color :transparent;
	color: $secondary4;
	@extend .transAll;
	&:hover{
		background-color: $secondary4;
		color: $secondary5;
		@extend .transAll;
	}
}

.table-condensed>tbody>tr>td{padding:10px 5px;}
.checkbox input[type=checkbox]{position:relative;margin:0;}
.checkbox{margin-top:0;}
label{font-weight:200;}
input,.form-control{
	border:none;border-radius:0;background-color:$secondary4;box-shadow:none;color:$secondary5;
}

:-webkit-input-placeholder {color:#fff;}
::-webkit-input-placeholder {color:#fff;}
:-moz-placeholder {color:#fff;}
::-moz-placeholder {color:#fff;}
:-ms-input-placeholder {color:#fff;}
input:-moz-placeholder { color:#fff; }

.table,.table-responsive,table,tbody{border:none;border-radius:0;box-shadow:none;}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td{border:none;border-radius:0;box-shadow:none;}

.transAll{
	transition: all 0.3s ease;-ms-transition: all 0.3s ease;-webkit-transition: all 0.3s ease;
}
.transAllSlow{
	transition: all 0.8s ease;-ms-transition: all 0.8s ease;-webkit-transition: all 0.8s ease;
}

.arrow-rotate {
	-webkit-transform: rotate(180deg);  /* Chrome, Safari 3.1+ */
		-moz-transform: rotate(180deg);  /* Firefox 3.5-15 */
			-ms-transform: rotate(180deg);  /* IE 9 */
				-o-transform: rotate(180deg);  /* Opera 10.50-12.00 */
				 transform: rotate(180deg);  /* Firefox 16+, IE 10+, Opera 12.50+ */
}
.arrow-transition {
	-webkit-transition: all 0.3s ease-out;  /* Chrome 1-25, Safari 3.2+ */
		 -moz-transition: all 0.3s ease-out;  /* Firefox 4-15 */
			 -o-transition: all 0.3s ease-out;  /* Opera 10.50–12.00 */
					transition: all 0.3s ease-out;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
}

body.open .slideRight{
	animation-name: slideRight;
	-webkit-animation-name: slideRight; 
	-ms-animation-name: slideRight; 

	animation-duration: 1s; 
	-webkit-animation-duration: 1s;
	-ms-animation-duration: 1s;

	animation-timing-function: ease-in-out; 
	-webkit-animation-timing-function: ease-in-out;
	-ms-animation-timing-function: ease-in-out;

	visibility: visible !important; 
}

@keyframes slideRight {
	0% {
		transform: translateX(-150%);
	}
	50%{
		transform: translateX(8%);
	}
			
	100% {
		transform: translateX(0%);
	} 
}

@-webkit-keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%);
	}
	50%{
		-webkit-transform: translateX(8%);
	}
	
	100% {
		-webkit-transform: translateX(0%);
	}
}

@-ms-keyframes slideRight {
	0% {
		-ms-transform: translateX(-150%);
	}
	50%{
		-ms-transform: translateX(8%);
	}
	
	100% {
		-ms-transform: translateX(0%);
	}
}


.floating{


	animation-duration: 1.5s; 
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		transform: translateX(0%);  
	}
	50% {
		transform: translateX(22%);  
	} 
	100% {
		transform: translateX(0%);
	}     
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateX(0%);  
	}
	50% {
		-webkit-transform: translateX(22%);  
	} 
	100% {
		-webkit-transform: translateX(0%);
	}     
}

.text-light {
	color: $white !important;
	p, h1, h2, h3, h4, h5, h6, ul li::marker, ul li::before, a {
		color: $white !important;
	}
	p a {
		text-decoration: underline;
		&:hover {
			color: $white;
			text-decoration: underline;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $white;
			text-decoration: underline;
		}
	}
}
