.hosted-video-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}

.video-thumb-container {
	position: relative;
	overflow: hidden;
	&:not(.no-hover):hover {
		img, video {
			opacity: 1.0;
			filter: blur(0px) saturate(1);
			-webkit-filter: blur(0px) saturate(1);
			-moz-filter: blur(0px) saturate(1);
			-ms-filter: blur(0px) saturate(1);
			-o-filter: blur(0px) saturate(1);
		}
		.video-thumb-overlay {
			background-color: rgba($secondary1, 0.0);
			pointer-events: none;
			// backdrop-filter: blur(4px);
			// -webkit-backdrop-filter: blur(4px);
			// -moz-backdrop-filter: blur(4px);
			// -ms-backdrop-filter: blur(4px);
			// -o-backdrop-filter: blur(4px);
			.video-thumb-button-container {
				.video-thumb-button {
					color: $white;
					.fac, .fa {
						color: $white;
					}
				}
			}
		}
	}
	&:not(.no-hover)img, &:not(.no-hover)video {
		opacity: 1;
		filter: blur(2px) saturate(80%);
		-webkit-filter: blur(2px) saturate(80%);
		-moz-filter: blur(2px) saturate(80%);
		-ms-filter: blur(2px) saturate(80%);
		-o-filter: blur(2px) saturate(80%);
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
	}
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-transition: opacity 1.0s ease-out;
		-moz-transition: opacity 1.0s ease-out;
		-ms-transition: opacity 1.0s ease-out;
		-o-transition: opacity 1.0s ease-out;
		transition: opacity 1.0s ease-out;
	}
	&.video-thumb-container-hide-video {
		video {
			opacity: 0;
		}
	}
	.video-thumb-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($secondary1, 0.25);
		display: flex;
		justify-content: center;
		align-items: center;
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
		.video-thumb-button-container {
			padding: 1.25em;
			border-radius: 1.25em;
			background-color: rgba($secondary1, 0.75);
			backdrop-filter: blur(6px) saturate(180%);
			-webkit-backdrop-filter: blur(6px) saturate(180%);
			-moz-backdrop-filter: blur(6px) saturate(180%);
			-ms-backdrop-filter: blur(6px) saturate(180%);
			-o-backdrop-filter: blur(6px) saturate(180%);
			.video-thumb-button {
				margin: 0;
				padding: 0;
				text-align: center;
				font-family: $fontMain;
				color: $secondary4;
				font-size: 0.5em;
				text-transform: uppercase;
				letter-spacing: 0.25em;
				transition: all 1.0s ease-out;
				-webkit-transition: all 1.0s ease-out;
				-moz-transition: all 1.0s ease-out;
				-ms-transition: all 1.0s ease-out;
				-o-transition: all 1.0s ease-out;
				transition: all 1.0s ease-out;
				.fac, .fa {
					font-size: 6.0em;
					padding-bottom: 0.125em;
					z-index: 6;
				}
			}
		}
	}
}

.video-responsive {
	width: 100%;
	height: auto;
	aspect-ratio: 16 / 9;
	iframe {
		width: 100%;
		height: auto;
		aspect-ratio: 16 / 9;
	}
}
