/** ********* footer.sass contents *********** 
footer
	upper-footer
		
	lower-footer
		

***************************************** **/
#true-footer{
	li,a,p,{font-size:0.8em;margin:0;color:$secondary4;}
	.footer-logo{background:transparent url(/stylesheets/webArt/logos/third-eye-white.png) center center no-repeat;background-size:contain;height:60px;width:130px; margin:0 auto;}

	
	background-color:$secondary2;padding:30px 0 20px;text-align:center;
	.social-links{
		li{
			border:1px solid $secondary5;width:20%;@extend.transAll;
			a{
				color:$secondary5;font-size:2em;width:100%;height:100%;display:block;padding:10px 5px;@extend.transAll;
			}
			&:hover{
				border:1px solid $primary1;
				a{
					color:$primary1;
				}
			}
		}
	}
	.footer-link-list {
		li {
			a {
				margin: 0 0.3125rem;
			}
		}
	}
	.tooltip.top .tooltip-inner {
	    background-color:$primary1;z-index:20;position:relative;
	}
	.tooltip.top .tooltip-arrow {
	      border-top-color: $primary1;
	}
	
}

@media (min-width: 768px) {
	#true-footer{
		.footer-logo{
			margin:0;
		}
		
		
			address{
				text-align:left;
			}
			.social-links{
				li{
					border:none;width:auto;
					a{
						padding:4px;
					}
					&:hover{
						border:none;
						a{
							color:$primary1;
						}
					}
				}
			}
			text-align:right;

		
	}

}
@media (min-width:  992px) {
	
}
@media (min-width:  1200px) {

}