//Add img-fluid responsiveness to all img tags within the body section.
.body {
	img {
		max-width: 100%;
		height: auto;
	}
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-zoom-hover {
	overflow: hidden;
	&:hover {
		img {
			transform: scale(1.125);
		}
	}
	img {
		-webkit-transition: transform 2.0s ease-out;
		-moz-transition: transform 2.0s ease-out;
		-ms-transition: transform 2.0s ease-out;
		-o-transition: transform 2.0s ease-out;
		transition: transform 2.0s ease-out;
	}
}
