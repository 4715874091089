/** Colors **/
$white: #FFFFFF;
$black: #000000;

$primary1: #13a89e; /** rgba(19,168,158,1) **/
$primary2: #1cf4e6; /** rgba(28,244,230,1) **/
$primary3: #0d756e; /** rgba(11,117,110,1) **/

$secondary1: #545454; /** rgba(84,84,84,1) **/
$secondary2: #222222;
$secondary3: #333333;
$secondary4: #999999;
$secondary5: #eeeeee; /** rgba(238,238,238,1) **/

/** Fonts **/
$fontHeading:"proxima-nova",sans-serif;
$fontMain:"proxima-nova",sans-serif;


/******************************************************************************************
PIXEL TO REM CONVERSION TABLE - Root Size: 16px
-------------------------------------------------------------------------------------------
PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM
-------------------------------------------------------------------------------------------
10 = 0.625   |  20 = 1.25    |  30 = 1.875   |  40 = 2.5     |  50 = 3.125   |  60 = 3.75
11 = 0.6875  |  21 = 1.3125  |  31 = 1.9375  |  41 = 2.5625  |  51 = 3.1875  |  61 = 3.8125
12 = 0.75    |  22 = 1.375   |  32 = 2       |  42 = 2.625   |  52 = 3.25    |  62 = 3.875
13 = 0.8125  |  23 = 1.4375  |  33 = 2.0625  |  43 = 2.6875  |  53 = 3.3125  |  63 = 3.9375
14 = 0.875   |  24 = 1.5     |  34 = 2.125   |  44 = 2.75    |  54 = 3.375   |  64 = 4
15 = 0.9375  |  25 = 1.5625  |  35 = 2.1875  |  45 = 2.8125  |  55 = 3.4375
16 = 1       |  26 = 1.625   |  36 = 2.25    |  46 = 2.875   |  56 = 3.5
17 = 1.0625  |  27 = 1.6875  |  37 = 2.3125  |  47 = 2.9375  |  57 = 3.5625
18 = 1.125   |  28 = 1.75    |  38 = 2.375   |  48 = 3       |  58 = 3.625
19 = 1.1875  |  29 = 1.8125  |  39 = 2.4375  |  49 = 3.0625  |  59 = 3.6875
-------------------------------------------------------------------------------------------
******************************************************************************************/
