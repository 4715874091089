// Blocks General
.content-blocks-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-snap-type: y proximity;
	-webkit-overflow-scrolling: touch;
}

.block-outer-container {
	margin: 0 !important;
	padding: 0 !important;
}

.block-inner-container {
	padding: 0 !important;
}

.block-scroll-snap-center {
	scroll-snap-align: center;
	scroll-snap-stop: always;
}

.block-outer-container-center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.block-inner-container-center-content {
	display: flex;
	justify-content: center;
	align-items: center;
	.row {
		width: 100%;
	}
}

.block-headline-text {
	margin: 0;
	line-height: 1;
}

.block-subheadline-text, .block-subheadline-text p {
	margin: 0.3125em 0 0 0;
	text-transform: none;
	line-height: 1;
}

.block-button-group-container {
	padding-top: 1.25rem;
}

.block-background-image-overlay {
	position: absolute;
	z-index: -10;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.block-background-image-fixed {
	background-attachment: fixed !important;
	@supports (-webkit-touch-callout: none) {
		background-attachment: scroll !important;
	}
}

.block-background-image-cover {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.block-background-video {
	position: absolute;
	width: 100% !important;
	height: 100% !important;
	overflow: hidden;
}

.block-container-full, .block-container-content {
	position: relative;
}

.block-overlay-full, .block-overlay-content {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.divider-block-intro {
	visibility: hidden;
	margin: 0.3125rem 0;
}




// Images
.img-regular-padding {
	padding: 0 1.25em;
	@media (max-width: 991.9px) {
		padding: 2.5em 1.25em;
	}
}

.img-no-padding {
	padding: 0;
	@media (max-width: 991.9px) {
		padding: 2.5em 0;
	}
}

.img-square-crop, .img-circle-crop {
	position:relative;
}
.img-square-crop::after, .img-circle-crop::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.img-square-crop img, .img-circle-crop img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-repeat: no-repeat;
	@media (max-width: 991.9px) {
		padding: 2.5em;
	}
}

.img-circle-crop img {
	border-radius: 50%;
}

.img-overlay-sections {
	position: absolute;
	right: 2.5em;
	top: 0;
	@media (max-width: 991.9px) {
		position: static;
		padding: 2.5em 1.25em;
	}
}
.img-background-fill {
	display: flex;
	justify-content: stretch;
	align-items: flex-end;
	align-self: stretch;
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	figure {
		display: flex;
		justify-content: stretch;
		align-items: flex-end;
		visibility: visible;
		width: 100%;
		height: 100%;
		img, a img {
			visibility: hidden;
			width: 100%;
		}
		figcaption {
			visibility: visible;
			width: 100%;
		}
	}
}
.block-single-image-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

//Text One Column
.text-one-column-body-copy {
	// display: flex;
	// justify-content: center;
}




// Large Text Callout
.large-text-callout-container {
	h2.large-text-callout-headline-text {
		font-weight: 400;
		font-size: 2em;
		color: $secondary3;
	}
	h3.large-text-callout-headline-text {
		font-size: 1.75em;
	}
	h4.large-text-callout-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}

	h4.large-text-callout-subheadline-text {
		font-weight: 600;
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.large-text-callout-subheadline-text {
		font-weight: 600;
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.large-text-callout-subheadline-text {
		font-weight: 400;
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.large-text-callout-body-copy {
		p {
			font-size: clamp(1.75em, -webkit-calc(1.75em + 0.75 * ((100vw - 36em) / 39)), 2.5em);
			font-size: clamp(1.75em, -moz-calc(1.75em + 0.75 * ((100vw - 36em) / 39)), 2.5em);
			font-size: clamp(1.75em, calc(1.75em + 0.75 * ((100vw - 36em) / 39)), 2.5em);
			line-height: 1.2em;
		}
	}
}




//Pull Quote
.pull-quote-container {
	h2.pull-quote-headline-text {
		font-weight: 400;
		font-size: 2em;
		color: $secondary3;
	}
	h3.pull-quote-headline-text {
		font-size: 1.75em;
	}
	h4.pull-quote-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-headline-text {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h4.pull-quote-subheadline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-subheadline-text {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.pull-quote-subheadline-text {
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.pull-quote-body-copy {
		p {
			font-size: 2.0em;
			line-height: 1.25em;
		}
	}
	cite.pull-quote-citation {
		display: block;
		color: $secondary2;
		font-size: 1.5em;
		font-style: normal;
		font-weight: 600;
		line-height: 1em;
		margin: 0.625em 0 1.25em 0;
		padding: 0;
	}
}




// Text-With-Image Block
.block-text-image-body-column {
	padding: 0 2.5em;
	@media (max-width: 991.9px) {
		padding: 0;
	}
	.block-text-image-headline-container {
		padding-bottom: 2.5em;
	}
}
.block-text-with-image-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}




// Text-With-Gallery Block
.block-container-text-with-gallery {
	.block-text-with-gallery-body-column {
	}
	.block-text-with-gallery-gallery-column {
		overflow: hidden;
		.swiper-slide {
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			transform-style: preserve-3d;
		}
	}
}




//Stat Facts Row
.stat-fact-number {
	display: inline;
	margin: 0;
	color: $secondary3;
	font-size: 5em;
	font-weight: 400;
	line-height: 1;
}
.stat-fact-prefix {
	display: inline;
	margin: 0;
	color: $secondary3;
	font-size: 3em;
	font-weight: 400;
	line-height: 1;
}
.stat-fact-suffix {
	display: inline;
	margin: 0;
	color: $secondary3;
	font-size: 3em;
	font-weight: 400;
	line-height: 1;
}
.stat-fact-desc {
	margin: 0;
	font-size: 1.2em;
	line-height: 1;
}
.stat-fact-citation {
	margin: 0;
	padding-top: 0.625em;
	font-size: 0.9em;
	font-weight: 400;
	line-height: 1;
	color: $secondary1;
}





//Image Callout Cards
.block-container-image-callout-cards {
	.card {
		border: none;
		border-radius: 0;
		.card-body {
			a {
				// color: $primary1;
				text-decoration: none;
				&:hover {
					color: $primary2;
					text-decoration: none;
					.image-callout-headline {
						color: $primary2;
						text-decoration: none;
					}
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $primary3;
					text-decoration: none;
					.image-callout-headline {
						color: $primary3;
						text-decoration: none;
					}
				}
			}
			p {
				font-size: 1.0em;
				line-height: 1.2em;
			}
		}
	}
}





//Image Icon Row
.image-icon-container {
	padding: 1.25em;
}
.image-icon-headline {
	margin: 1.25em 0 0 0;
	color: $secondary1;
}
.image-icon-link {
	&:hover {
		text-decoration: none;
	}
}
.image-icon-text p {
	color: $secondary3;
	line-height: 1.2em;
	margin-top: 1.25em;
}
.image-icon-card {
	height: 100%;
	margin: 0;
	padding: 0 0 2.5em 0;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	.image-icon-card-img-top {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		.image-icon-image {
			margin: 0;
			padding: 0;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.image-icon-card-body {
		width: 100%;
		margin: 0;
		padding: 0;
		align-self: flex-end;
		flex-grow: 0;
	}
	.image-icon-card-footer {
		margin: 0;
		padding: 0;
		align-self: flex-end;
	}
}






//Video Block
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; overflow:
	hidden;
	max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}





//Image Gallery Block
.block-container-image-gallery {
	.block-image-gallery-thumb-container {
		padding: 0.625em;
		.block-image-gallery-thumb {

		}
	}
	.block-image-gallery-masonry-grid-sizer,
	.block-image-gallery-masonry-grid-item {
		padding: 0.625em;
	}
}



//Multi Image Galleries Block
.block-multi-image-galleries-gallery-all-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	.block-multi-image-galleries-slider {
		.image-slider-wrapper {
			.image-slide {
				.image-slide-image {
					opacity: 1.0;
					border: none;
					-webkit-filter: blur(0px) grayscale(0%) contrast(100%);
					-moz-filter: blur(0px) grayscale(0%) contrast(100%);
					-ms-filter: blur(0px) grayscale(0%) contrast(100%);
					-o-bfilter: blur(0px) grayscale(0%) contrast(100%);
					filter: blur(0px) grayscale(0%) contrast(100%);
				}
			}
		}
	}
}



//Background Image Callout Cards
.background-callout-card {
	width: 100%;
	border: none;
	border-radius: 0;
	.card-img-back {
		border-radius: 0;
		.card-img {
			border-radius: 0;
		}
	}
	.card-img-overlay {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		border-radius: 0;
		background-color: rgba($secondary3, 0.4);
		.background-callout-headline {
			color: $white;
			font-size: 2.25em;
			line-height: 0.875;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}
		.background-callout-subheadline {
			color: $white;
		}
		.background-callout-body p {
			color: $white;
			font-size: 1.0em;
			line-height: 1.2em;
		}
	}
}



//Accordion List Block
.block-container-accordion-list {
	.accordion-block {
		margin: 0;
		padding: 0;
		.card {
			margin: 0;
			padding: 0;
			border-bottom: 1px solid $secondary4;
			-webkit-transition: border 1.0s ease-out;
			-moz-transition: border 1.0s ease-out;
			-ms-transition: border 1.0s ease-out;
			-o-transition: border 1.0s ease-out;
			transition: border 1.0s ease-out;
			.card-header {
				margin: 0;
				padding: 1.25em 0.5625em;
				padding-top: clamp(0.75em, -webkit-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-top: clamp(0.75em, -moz-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-top: clamp(0.75em, calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, -webkit-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, -moz-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				// border-bottom: 1px solid $gray-white2;
				-webkit-transition: border 1.0s ease-out;
				-moz-transition: border 1.0s ease-out;
				-ms-transition: border 1.0s ease-out;
				-o-transition: border 1.0s ease-out;
				transition: border 1.0s ease-out;
				.btn {
					margin: 0;
					padding: 0;
					font-size: clamp(1.3125rem, calc(1.3125rem + 0.5 * ((100vw - 36rem) / 39)), 1.8125rem);
					font-size: clamp(1.3125rem, -webkit-calc(1.3125rem + 0.5 * ((100vw - 36rem) / 39)), 1.8125rem);
					font-size: clamp(1.3125rem, -moz-calc(1.3125rem + 0.5 * ((100vw - 36rem) / 39)), 1.8125rem);
					font-weight: 400;
					line-height: 1;
					color: $primary2;
					text-transform: none;
					letter-spacing: 0;
					&:before {
						display: block;
						content: "";
						position: absolute;
						width: 0;
						height: 0.25rem;
						bottom: 0;
						left: 0;
						background-color: $primary1;
						visibility: hidden;
						-webkit-transition: transform 0.5s ease-out;
						-moz-transition: transform 0.5s ease-out;
						-ms-transition: transform 0.5s ease-out;
						-o-transition: transform 0.5s ease-out;
						transition: .5s ease-out;
					}
					&::after  {
						display: none;
						content: "";
					}
					&:hover {
						color: $primary2;
						&:before {
							visibility: visible;
							width: 100%;
						}
					}
					&.active.focus, &.active:focus, &.focus, &.focus:active, &:active:focus, &:focus {
						outline: 0 !important;
						outline-offset: 0 !important;
						background-image: none !important;
						-webkit-box-shadow: none !important;
						-moz-box-shadow: none !important;
						box-shadow: none !important;
						&:before {
							visibility: visible;
							width: 100%;
						}
					}
					&[aria-expanded="true"] {
						.fa, .fas, .fac { transform: rotate(180deg); }
					}
					.fa, .fas, .fac {
						display: inline-block;
						position: absolute;
						top: 0.75em;
						right: 0.5625em;
						transition: transform 300ms ease-in-out;
						transform: rotate(0deg);
						color: $primary1;
					}
				}
			}
			.card-body {
				font-size: clamp(1.0rem, calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
				font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
				font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
				padding: 1.25em 0.5625em;
				padding-top: clamp(0.75em, -webkit-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-top: clamp(0.75em, -moz-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-top: clamp(0.75em, calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, -webkit-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, -moz-calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				padding-bottom: clamp(0.75em, calc(0.75em + 0.5 * ((100vw - 36em) / 39)), 1.25em);
				p {
					padding: 0;
					font-size: clamp(1.0rem, calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
					font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
					font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.5 * ((100vw - 36rem) / 39)), 1.5rem);
				}
				h3 {
					font-size: 1.875rem;
					color: $secondary3;
				}
			}
		}
	}
}
