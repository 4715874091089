header {
	position: relative;
	width:100%;
	z-index:60;
	a.navbar-brand {
		position: relative;
		z-index: 200;
		text-indent:-9999em;
		background: transparent url(/stylesheets/webArt/logos/third-eye-white.png) left center no-repeat;
		background-size: contain;
		height: 55px;
		width: 180px;
		margin: 18px 0 0 10px;
	}
	.start-link {
		margin: 10px 0px;
		position: absolute;
		top: 25px;
		right: 200px;
		z-index: 200;
		width: 140px;
		a {
			color: $secondary5;
			font-weight: 200;
			font-size: 16px;
		}
	}	
}

#interiorBody header {
	padding-bottom: 20px;
}

nav {
	.cd-primary-nav {
		.menu-posts {
			h3 {
				color: $primary1;
			}
			p {
				color: $secondary4;
			}
		}
		.main-nav{
			text-align: center;
			padding-top: 100px;
			li {
				a {
					color: $primary1;
					font-size: 1.5em;
					&:hover {
						color: $primary2;
					}
				}
			}
			h4 {
				margin: 0;
				color: $secondary4;
			}
			.nav-space {
				margin-top:20px;
			}
			.social-links { 
				margin-top: 6px;
				a {
					color: $secondary5;
					&:hover {
						color: $primary1;
					}
				}
				li {
					padding-right: 0;
					border: none;
					width: auto;
					@extend .transAll;
					a {
						color: $secondary5;
						font-size: 1.8em;
						width: 100%;
						height: 100%;
						display: block;
						padding: 0 8px;
						@extend .transAll;
					}
					&:hover {
						border: none;
						a {
							color: $primary1;
						}
					}
				}
			}
			.tooltip.bottom .tooltip-inner {
				background-color: $primary1;
				z-index: 20;
				position: relative;
			}
			.tooltip.bottom .tooltip-arrow {
			 border-bottom-color: $primary1;
			}
			
		}
	}
}

/* -------------------------------- 

Primary style

-------------------------------- */
*, *::after, *::before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*::after, *::before {
	content: '';
}


/* -------------------------------- 

Main components 

-------------------------------- */


.cd-logo, .cd-nav-trigger {
	position: absolute;
	display: inline-block;
}

.cd-logo {
	top: 38px;
	left: 5%;
}
.cd-logo img {
	display: block;
}

.cd-nav-trigger {
	top: 28px;
	right: 5%;
	height: 44px;
	width: 44px;
	z-index: 5;
	text-indent: -40px;
	white-space: nowrap;
	color:#eeeeee;
	line-height:2.8;
}
a.cd-nav-trigger:active,a.cd-nav-trigger:focus,a.cd-nav-trigger:hover{
	color:#eeeeee;text-decoration:none;
}

.cd-nav-trigger .cd-icon {
	/* icon created in CSS */
	position: absolute;
	left: 50%;
	top: 50%;
	bottom: auto;
	right: auto;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	display: inline-block;
	width: 26px;
	height: 2px;
	background-color: #ffffff;
	z-index: 10;
}
.cd-nav-trigger .cd-icon::before, .cd-nav-trigger .cd-icon:after {
	/* upper and lower lines of the menu icon */
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	/* Force Hardware Acceleration in WebKit */
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	/* apply transition to transform property */
	-webkit-transition: -webkit-transform .3s;
	-moz-transition: -moz-transform .3s;
	transition: transform .3s;
}
.cd-nav-trigger .cd-icon::before {
	-webkit-transform: translateY(-8px) rotate(0deg);
	-moz-transform: translateY(-8px) rotate(0deg);
	-ms-transform: translateY(-8px) rotate(0deg);
	-o-transform: translateY(-8px) rotate(0deg);
	transform: translateY(-8px) rotate(0deg);
}
.cd-nav-trigger .cd-icon::after {
	-webkit-transform: translateY(8px) rotate(0deg);
	-moz-transform: translateY(8px) rotate(0deg);
	-ms-transform: translateY(8px) rotate(0deg);
	-o-transform: translateY(8px) rotate(0deg);
	transform: translateY(8px) rotate(0deg);
}
.cd-nav-trigger::before, .cd-nav-trigger::after {
	/* 2 rounded colored backgrounds for the menu icon */
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	height: 100%;
	width: 100%;
	/* Force Hardware Acceleration in WebKit */
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	transition-property: transform;
}
.cd-nav-trigger::before {
	background-color: transparent;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-delay: 0.4s;
	-moz-transition-delay: 0.4s;
	transition-delay: 0.4s;
}
.cd-nav-trigger::after {
	background-color: #13a89e;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	transition-duration: 0s;
	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	transition-delay: 0s;
}
.cd-nav-trigger.close-nav::before {
	/* user clicks on the .cd-nav-trigger element - 1st rounded background disappears */
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
}
.cd-nav-trigger.close-nav::after {
	/* user clicks on the .cd-nav-trigger element - 2nd rounded background appears */
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-delay: 0.4s;
	-moz-transition-delay: 0.4s;
	transition-delay: 0.4s;
}
.cd-nav-trigger.close-nav .cd-icon {
	/* user clicks on the .cd-nav-trigger element - transform the icon */
	background-color: rgba(255, 255, 255, 0);
}
.cd-nav-trigger.close-nav .cd-icon::before, .cd-nav-trigger.close-nav .cd-icon::after {
	background-color: white;
}
.cd-nav-trigger.close-nav .cd-icon::before {
	-webkit-transform: translateY(0) rotate(45deg);
	-moz-transform: translateY(0) rotate(45deg);
	-ms-transform: translateY(0) rotate(45deg);
	-o-transform: translateY(0) rotate(45deg);
	transform: translateY(0) rotate(45deg);
}
.cd-nav-trigger.close-nav .cd-icon::after {
	-webkit-transform: translateY(0) rotate(-45deg);
	-moz-transform: translateY(0) rotate(-45deg);
	-ms-transform: translateY(0) rotate(-45deg);
	-o-transform: translateY(0) rotate(-45deg);
	transform: translateY(0) rotate(-45deg);
}

.cd-primary-nav {
	/* by default it's hidden */
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
 
	z-index: 3;
	background-color:#222222;
	overflow: auto;
	/* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
	-webkit-overflow-scrolling: touch;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: visibility 0s, opacity 0.3s;
	-moz-transition: visibility 0s, opacity 0.3s;
	transition: visibility 0s, opacity 0.3s;
}

.cd-primary-nav a {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: color 0.2s;
	-moz-transition: color 0.2s;
	transition: color 0.2s;
}
.no-touch .cd-primary-nav a:hover {
	color: #ffffff;
}
.cd-primary-nav.fade-in {
	/* navigation visible at the end of the circle animation */
	visibility: visible;
	opacity: 1;
}



.cd-overlay-nav, .cd-overlay-content {
	/* containers of the 2 main rounded backgrounds - these containers are used to position the rounded bgs behind the menu icon */
	position: fixed;
	top: 18px;
	right: 5%;
	height: 4px;
	width: 4px;
	-webkit-transform: translateX(-20px) translateY(20px);
	-moz-transform: translateX(-20px) translateY(20px);
	-ms-transform: translateX(-20px) translateY(20px);
	-o-transform: translateX(-20px) translateY(20px);
	transform: translateX(-20px) translateY(20px);
}
.cd-overlay-nav span, .cd-overlay-content span {
	display: inline-block;
	position: absolute;
	border-radius: 50%;
	/* Force Hardware Acceleration in WebKit */
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	will-change: transform;
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
}
.cd-overlay-nav.is-hidden, .cd-overlay-content.is-hidden {
	/* background fades out at the end of the animation */
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity .3s 0s, visibility 0s .3s;
	-moz-transition: opacity .3s 0s, visibility 0s .3s;
	transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-overlay-nav {
	/* main rounded colored bg 1 */
	z-index: 2;
}
.cd-overlay-nav span {
	background-color: rgba(11,117,110,0.8);
}

.cd-overlay-content {
	/* main rounded colored bg 2 */
	z-index: 4;
}
.cd-overlay-content span {
	background-color: rgba(28,244,230,0.8)
}




@media (min-width: 768px) {
	header{
		a.navbar-brand{
			position: relative;height:70px;margin:20px 0 0 0;
		}
	}
	nav{
		.cd-primary-nav{
			padding-top:130px;
			.main-nav{
				text-align:right;padding-top:0;
				li{
					a{font-size:1.8em;}
				}
				.nav-space{margin-top:30px;}
				.social-links{ 
					li{
						a{
							font-size:2em;padding:4px;
						}
					}
				}
			}
		}
	}

}
@media (min-width: 992px) {
	
}
@media (min-width: 1200px) {
	
}

