.btn {
	// margin: 0.25em 0.125em;
	// padding: 1.0em 2.0em;
	// font-size: 1.125em;
	// font-size: clamp(0.625em, -webkit-calc(0.625em + 0.25 * ((100vw - 36em) / 39)), 0.875rem);
	// font-size: clamp(0.625em, -moz-calc(0.625em + 0.25 * ((100vw - 36em) / 39)), 0.875rem);
	// font-size: clamp(0.625em, calc(0.625em + 0.25 * ((100vw - 36em) / 39)), 0.875rem);
	// font-weight: 600;
	// line-height: 1;
	text-decoration: none !important;
	-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	@extend .btn-thirdeye;
	&:hover {
		@extend .btn-thirdeye;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		@extend .btn-thirdeye;
		text-decoration: none;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0  !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
}

.btn-sm {
	// font-size: 0.875em;
	// font-size: clamp(0.8em, -webkit-calc(0.8em + 0.075 * ((100vw - 36em) / 39)), 0.875rem);
	// font-size: clamp(0.8em, -moz-calc(0.8em + 0.075 * ((100vw - 36em) / 39)), 0.875rem);
	// font-size: clamp(0.8em, calc(0.8em + 0.075 * ((100vw - 36em) / 39)), 0.875rem);
	// padding: 0.75em 1.25em;
}

.btn-lg {
	// font-size: 1.125em;
	// font-size: clamp(1.0em, calc(1.0em + 0.25 * ((100vw - 36em) / 39)), 1.25rem);
	// font-size: clamp(1.0em, calc(1.0em + 0.25 * ((100vw - 36em) / 39)), 1.25rem);
	// font-size: clamp(1.0em, calc(1.0em + 0.25 * ((100vw - 36em) / 39)), 1.25rem);
	// padding: 1.0em 2.0em;
}

.btn-primary {
	@extend .btn-thirdeye;
	&:hover {
		@extend .btn-thirdeye;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $primary1;
		border-color: $primary1;
	}
}

.btn-secondary {
	color: $white;
	background-color: $secondary1;
	border-color: $secondary1;
	&:hover {
		color: $white;
		background-color: $secondary4;
	border-color: $secondary4;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $secondary3;
		border-color: $secondary3;
	}
}

.btn-gray {
	color: $white;
	background-color: $secondary1;
	border-color: $secondary1;
	&:hover {
		color: $primary2;
		background: $secondary4;
		border-color: $secondary4;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background: $secondary3;
		border-color: $secondary3;
	}
}

.btn-dark {
	color: $white;
	background-color: $black;
	border-color: $black;
	&:hover {
		color: $primary3;
		background-color: $secondary3;
		border-color: $secondary3;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $secondary2;
		border-color: $secondary2;
	}
}

.btn-light {
	color: $primary1;
	background-color: $white;
	border-color: $white;
	&:hover {
		color: $primary3;
		background-color: $white;
		border-color: $white;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $primary1;
		background-color: $white;
		border-color: $white;
	}
}

.btn-outline-primary {
	@extend .btn-thirdeye2;
	&:hover {
		@extend .btn-thirdeye2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $primary3;
		border-color: $primary3;
	}
}

.btn-outline-secondary {
	background-color: none;
	color: $secondary1;
	border-color: $secondary1;
	&:hover {
		color: $white;
		background-color: $secondary4;
		border-color: $secondary4;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $secondary3;
		border-color: $secondary3;
	}
}

.btn-outline-gray {
	color: $secondary1;
	border-color: $secondary1;
	&:hover {
		color: $white;
		background-color: $secondary5;
		border-color: $secondary5;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $secondary3;
		border-color: $secondary3;
	}
}

.btn-outline-dark {
	background-color: none;
	color: $black;
	border-color: $black;
	&:hover {
		color: $white;
		background-color: $primary1;
		border-color: $primary1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $primary3;
		border-color: $primary3;
	}
}

.btn-outline-light {
	background-color: none;
	color: $white;
	border-color: $white;
	&:hover {
		color: $white;
		background-color: $primary3;
		border-color: $primary1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $primary3;
		border-color: $primary3;
	}
}

.btn-link {
	color: $primary1;
	text-decoration: none;
	border: none;
	&:hover {
		color: $primary2;
		text-decoration: underline;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $primary3;
		text-decoration: underline;
		border: none;
	}
}
