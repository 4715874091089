/** ********* home.sass contents *********** 
introduction
	home-heading-upper
	home-heading-lower

***************************************** **/
@-webkit-keyframes color-change {
	0% { 
		color: #13a89e; 
	}
	100% { 
		color: #fff; 
	}
}
@keyframes color-change {
	0% { 
		color: #13a89e; 
	}
	100% { 
		color: #fff; 
	}
}

.hero {
	height:250px;
	margin-bottom:50px;
	padding-top:40px;
	.text-container{
		height:372px;
		.intro-text{
			width:100%;
			h2{text-indent:-9999em; background-image:url(/stylesheets/webArt/home-animation/text-see.png); background-repeat:no-repeat;background-position:bottom center; background-size:contain; height:150px;}
		}
		.tag-line{
			span{float:left;color:#FFF;}
			span:nth-child(1){width:26%; }
			span:nth-child(2){width:35%;}
			span:nth-child(3){width:39%;}
			.text-left{
					animation: color-change 4.5s ease-in 0s forwards;
					-webkit-animation: color-change 4.5s ease-in 0s forwards;
			}
			.text-center{
				animation: color-change 4s ease-in 2s forwards;
					-webkit-animation: color-change 4s ease-in 2s forwards;
			}
			.text-right{
				animation: color-change 4s ease-in 4s forwards;
					-webkit-animation: color-change 4s ease-in 4s forwards;
			}
		}
	}
}

.intro-animation {
	height: 200px;
	width: 250px;
	display: block;
	margin: auto;
}

.progress {
	width: 100%;
	margin-bottom: 0;
	border-radius: 0;
	height: 6px;
	.progress-bar {
		background-color: $primary2;
	}
}

.home-heading-title {
	background-color: $primary1;
	text-align:center;
	h1 {
		color: $secondary5;
		// margin: 20px 0;
		font-size: 24px;
		font-weight: 200;
		b, strong {
			font-weight: 500;
		}
	}
}

.mouse-icon {
	height: 50px;
	width: 35px;
	display: block;
	margin: 0 auto;
}

.home-msg {
	background-color: $primary3;
	p {
		color:$secondary5;
		margin:40px 0;}
}


@media (min-width: 768px) {
	// .introduction {
	// 	min-height:500px;
	// 	padding-top:70px;
	// }
	.hero{
			height:400px;
			// min-height:500px;
			margin-bottom: 190px;
			padding-top:70px;
			.text-container{
			.intro-text{
				h2{height:200px;}
			}
			.list-inline{
				margin:0 auto;max-width:700px;
			}
		}
	}
	.home-heading-title{
		h1{font-size:36px;}
	}
	.intro-animation{
		height:270px;width:340px;
	}
}

@media (min-width:  992px) {
	.hero {
		margin-bottom:230px;
		.text-container{
			.intro-text{margin-top: 75px;}
			.tag-line{
				span{font-size:16px;}
			}
		}
	}
	.intro-animation{
		height:330px;width:430px;
	}
}

@media (min-width:  1200px) {
	.hero {
		margin-bottom:270px;
		.text-container{
			.intro-text{
				margin-top: 60px;
				h2{height:220px;}
			}
			.tag-line{
				span{font-size:20px;}
			}
		}
	}
	.intro-animation{
		height:340px;width:480px;
	}
	.mouse-icon{
		margin: 50px auto;
	}
}

@media (min-width:  1400px) {
	.hero {
		.text-container {
			padding-top: 20px;
			.intro-text{
				margin-top:0px;
				h2{height:300px;}
			}
			li:nth-of-type(2){width:34%;}
		}
	}
	.intro-animation{
		height:385px;width:520px;
	}
}

.mouse-icon {
	svg{
		cursor: pointer;
		.st1 {
			stroke: #fff;
			fill: #fff;
			display: inline-block;
		}
		.down-arrow {
			animation:scrollDown 1.25s linear infinite;
		}
	}
}

@keyframes scrollDown {
	0% {
		transform: translate(0,0px);opacity:0;
	}
	10% {
	opacity:1;
	}
	70%{
		transform: translate(0,4px);
	}
	100% {
		transform: translate(0,7px);opacity:0;
	} 
}








